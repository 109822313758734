// Imports
// ------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query NewsFeatured {
				news: allDatoCmsNewsPost(
					limit: 1
					sort: { order: DESC, fields: meta___firstPublishedAt }
				) {
					posts: nodes {
						slug
						meta {
							firstPublishedAt
						}
						posted: meta {
							at: firstPublishedAt
						}
						title
						featuredImage {
							alt
							data: gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
						}
						author {
							name
							picture {
								alt
								data: gatsbyImageData(
									width: 36
									height: 36
									placeholder: BLURRED
									layout: FIXED
								)
							}
						}
					}
				}
			}
		`
	);
	return data.news;
};
