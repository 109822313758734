// Imports
// ------
import TransitionLink from 'gatsby-plugin-transition-link';
import styled, { css } from 'styled-components';
import { breakup, Section, H6 } from '@tackl';
import { pStyles, h5Styles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		z-index: 2;

		&:before {
			${props.theme.grad(0, props.theme.bc4, `${props.theme.bc4}00`)}
			z-index: 1;
			top: 0;
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
		}
	`
);

export const WhiteBG = styled.div(
	(props) => css`
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 2;
		height: ${props.theme.padS};
		background: ${props.theme.white};

		transform-origin: bottom center;
		transform: scaleY(0);
		will-change: transform;

		${breakup.medium`
			height: ${props.theme.padM};
		`}

		${breakup.large`
			height: ${props.theme.padL};
		`}
	`
);

export const Sweater = styled(TransitionLink)(
	(props) => css`
		position: relative;
		z-index: 3;
		display: block;
		overflow: hidden;
		border-radius: 0 12rem 0 0;

		${breakup.large`
			border-radius: 0 24rem 0 0;
		`}
	`
);

export const Background = styled.figure(
	(props) => css`
		position: relative;
		z-index: 1;
		overflow: hidden;

		height: 48rem;
		width: 100%;

		${breakup.medium`
            min-height: 60rem;
        `}

		${breakup.large`
            min-height: 48rem;
		    height: 75vh;
		    max-height: 60rem;
        `}

		.gatsby-image-wrapper {
			position: relative;
			z-index: 1;
			width: 100%;
			height: 100%;

			&:after {
				content: '';
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: ${props.theme.black}60;

				${breakup.large`
                    background: linear-gradient(
                        180deg,
                        rgba(000, 000, 000, 0) 0%,
                        rgba(000, 000, 000, 0.8) 80%,
                        rgba(000, 000, 000, 0.8) 100%
                    );
                `}
			}
		}
	`
);

export const Content = styled.div(
	(props) => css`
		position: absolute;
		top: ${props.theme.padS};
		left: 2.4rem;
		right: 2.4rem;
		z-index: 2;

		${breakup.medium`
            top: ${props.theme.padM};
            right: 6.5rem;
		    left: 6.5rem;
        `}

		${breakup.large`
            top: auto;
            bottom: 6rem;
            left: 6rem;
            right: auto;
            width: 100%;
            max-width: calc(8.333vw * 4 - 3.6rem);
        `}
	`
);

export const Title = styled.h2(
	(props) => css`
		${h5Styles}
		position: relative;

		padding-top: 2.4rem;
		margin-top: 2.4rem;
		transition: ${props.theme.ease};

		&:before {
			${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 6rem;
			height: 1px;
		}

		&:hover {
			${breakup.large`
				color: ${props.theme.white}60;
			`}
		}
	`
);

export const Date = styled.time(
	(props) => css`
		${pStyles}
	`
);

export const Author = styled.div(
	(props) => css`
		position: absolute;
		z-index: 2;
		bottom: ${props.theme.padS};
		right: 2.4rem;
		left: 2.4rem;

		display: flex;
		align-items: center;
		justify-content: flex-start;

		${breakup.medium`
            bottom: ${props.theme.padM};
            right: 6.5rem;
		    left: 6.5rem;
        `}

		${breakup.large`
            bottom: 6rem;
		    right: 6rem;
            left: auto;
            top: auto;
        `}
	`
);

export const AuthorPhoto = styled.figure(
	(props) => css`
		border-radius: 100%;
		width: 3.6rem;
		height: 3.6rem;
		overflow: hidden;

		.gatsby-image-wrapper {
			width: 100% !important;
			height: 100% !important;
		}

		img {
			border-radius: 100%;
		}
	`
);

export const AuthorName = styled(H6)(
	(props) => css`
		margin: 0 0 0 1.2rem;

		span {
			font-weight: ${props.theme.reg};
		}
	`
);
