// Imports
// ------
import React from 'react';
import Hero from './Hero';
import Featured from './Featured';
import Listing from './Listing';
import CallToAction from '@parts/CallToAction';
import ServicesCTA from '@parts/ServicesCTA';
import { useDato } from './data';

// Component
// ------
function News() {
	// NOTE • Data
	const { servicesTitle, servicesText, servicesImage } = useDato();

	return (
		<>
			<Hero />
			<Featured />
			<Listing />
			<ServicesCTA
				title={servicesTitle}
				text={servicesText}
				image={servicesImage}
			/>
			<CallToAction />
		</>
	);
}

export default News;
