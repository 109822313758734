// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Section, H1 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		margin: 6rem 0 0 0;
		padding: ${props.theme.padS} 0;

		${breakup.medium`
			padding: ${props.theme.padM} 0;
		`}

		${breakup.large`
			padding: ${props.theme.padL} 0;
		`}
	`
);

const slideIn = keyframes`
    from {
        transform: translateY(-1.2rem) rotate(-6deg);
        opacity: 0;
    }

    to {
        transform: translateY(0rem) rotate(0deg);
        opacity: 1;
    }
`;

export const Title = styled(H1)(
	(props) => css`
		position: relative;
		z-index: 1;

		.word {
			display: inline-flex;
		}

		.char {
			display: inline-flex;
			opacity: 0;

			${props.isAnimating &&
			css`
				animation: ${slideIn} 0.5s cubic-bezier(0.5, 0, 0.5, 1) both;
				animation-delay: calc(50ms * var(--char-index));
			`}
		}
	`
);
