// Imports
// ------
import React from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import { Jacket, Image, Content, Title, Detail, Grad } from './styles';

// Component
// ------
function Post({ image, title, author, date, slug }) {
	dayjs.extend(advancedFormat);

	// NOTE • Date
	const postedOn = dayjs(date).format('Do MMMM YYYY');

	// NOTE • Page Transtion Props
	const pageTrans = {
		to: `/news/${slug}`,
		className: 'cursor-hover',
		exit: { length: 1 },
		entry: { length: 1, delay: 1 },
	};

	return (
		<Jacket {...pageTrans}>
			<Image>
				<GatsbyImage image={image.data} alt={image.alt} />
			</Image>

			<Content>
				<Title>{title}</Title>

				<div>
					<Grad />

					<Detail isBold>
						<span>by </span>
						{author.name}
					</Detail>

					<Detail isBold>
						<span>on </span>
						{postedOn}
					</Detail>
				</div>
			</Content>
		</Jacket>
	);
}

export default React.memo(Post);
