// Imports
// ------
import React, { useRef, useState, useEffect } from 'react';
import { Row, Column } from '@waffl';
import { useDato } from './data';
import { loaderState } from '@states/loader';
import { observer } from 'mobx-react-lite';

// Styles
// ------
import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';
import { Jacket, Title } from './styles';

// Component
// ------
function Hero() {
	// NOTE • Data
	const { title } = useDato();

	// NOTE • States
	const [titleAni, setTitleAni] = useState(false);

	// NOTE • Refs
	const titleRef = useRef();

	// NOTE • Backup Data
	const backupTitle = `Latest News`;

	// NOTE • Split Title Characters
	useEffect(() => {
		let Splitting;
		if (typeof document !== 'undefined') {
			Splitting = require('splitting');

			Splitting({
				target: titleRef.current,
				by: 'chars',
			});
		}
	}, []);

	// NOTE • Check for loader status
	useEffect(() => {
		if (loaderState.isNeeded === false) {
			setTitleAni(true);
		}
	}, [loaderState.isNeeded]);

	return (
		<Jacket>
			<Row isExpanded>
				<Column small={12} medium={10} pushOnMedium={1} mpad>
					<Title ref={titleRef} isAnimating={titleAni}>
						{title ? title : backupTitle}
					</Title>
				</Column>
			</Row>
		</Jacket>
	);
}

export default observer(Hero);
