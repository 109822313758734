// Imports
// ------
import TransitionLink from 'gatsby-plugin-transition-link';
import styled, { css } from 'styled-components';
import { breakup, P, H6 } from '@tackl';

// Exports
// ------
export const Image = styled.figure(
	(props) => css`
		position: relative;
		padding-top: 112%;
		width: 100%;
		overflow: hidden;
		transition: ${props.theme.ease};

		.gatsby-image-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: all 1s ${props.theme.bezzy};

			img {
				border-radius: 0 12rem 0 0;
				transition: all 1s ${props.theme.bezzy};
			}
		}
	`
);

export const Content = styled.div(
	(props) => css`
		flex: 1;
		display: flex;
		flex-flow: column wrap;
		align-items: flex-start;
		justify-content: space-between;
		transition: ${props.theme.ease};

		color: inherit;
		padding: 0 0 2.4rem 0;

		${breakup.large` padding: 0; `}
	`
);

export const Grad = styled.span(
	(props) => css`
		${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}
		content: "";
		display: block;

		height: 1px;
		width: 6rem;
		margin: auto 0 1.2rem;

		transform-origin: left center;
		transition: ${props.theme.ease};

		${breakup.large`
            margin: auto 0 2.4rem;
        `}
	`
);

export const Title = styled(P)(
	(props) => css`
		position: relative;
		color: inherit;
		margin: 1.2rem 0 0 0;
		padding: 0 0 1.2rem 0;
		transition: ${props.theme.ease};

		${breakup.large`
            margin: 2.4rem 0 0 0;
            padding: 0 0 2.4rem 0;
        `}
	`
);

export const Detail = styled(H6)(
	(props) => css`
		color: inherit;
		margin: auto 0 0.6rem 0;
		transition: ${props.theme.ease};

		&:last-child {
			margin: 0;
		}

		span {
			font-weight: ${props.theme.reg};
		}
	`
);

export const Jacket = styled(TransitionLink)(
	(props) => css`
		position: relative;
		display: flex;
		flex-flow: column wrap;
		align-items: flex-start;
		justify-content: space-between;
		color: ${props.theme.bc4};

		${breakup.large` height: 100%; `}

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: -2.4rem;
			right: -2.4rem;
			height: 1px;
			background: ${props.theme.bc2}20;

			${breakup.large` display: none; `}
		}

		&:hover {
			${Title} {
				${breakup.large`
					color: ${props.theme.bc1};
				`}
			}

			${Detail} {
				${breakup.large`
					opacity: 0.6;
				`}
			}

			${Grad} {
				${breakup.large`
					transform: scaleX(2);
				`}
			}
		}
	`
);
