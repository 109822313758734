// Imports
// ------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query NewsHero {
				news: datoCmsNewsPage {
					title: heroTitle
				}
			}
		`
	);
	return data.news;
};
