// Imports
// ------
import React, { useState, useEffect } from 'react';
import Post from './Post';
import OnImagesLoaded from 'react-on-images-loaded';
import { Row, Column } from '@waffl';
import { useDato } from './data';
import { AnimatePresence, motion } from 'framer-motion';

// Styles
// ------
import { Jacket, Content, LoadMore, Loader } from './styles';

// Component
// ------
function Listing() {
	// NOTE • Data
	const { posts } = useDato();

	// NOTE • States
	const [visible, setVisible] = useState(9);
	const [allPosts, setAllPosts] = useState([]);
	const [showImages, setShowImages] = useState(false);

	// NOTE • Load more
	const handleLoadmore = () => {
		setVisible(visible + 9);
	};

	// NOTE • Handle Images
	const handleImages = () => {
		setShowImages(true);
	};

	// NOTE • Onload: Update state with posts
	useEffect(() => {
		setAllPosts(posts);
	}, [posts]);

	// NOTE • Loader Props
	const label = `View more`;
	const loaderProps = {
		'data-text': label,
		onClick: handleLoadmore,
	};

	// NOTE • Post Animation
	const postAni = {
		initial: {
			opacity: 0,
			y: `2.4rem`,
		},
		animate: {
			opacity: 1,
			y: `0rem`,
		},
	};

	// NOTE • Load more animation
	const loadAni = {
		initial: {
			opacity: 0,
		},
		animate: {
			opacity: 1,
		},
		exit: {
			opacity: 0,
		},
	};

	// NOTE • Image Handler Props
	const loadProps = {
		onLoaded: handleImages,
		onTimeout: handleImages,
		timeout: 5000,
	};

	return (
		<Jacket>
			<Row isExpanded>
				<Column small={12} medium={10} pushOnMedium={1} mpad>
					<OnImagesLoaded {...loadProps}>
						<AnimatePresence>
							<Content>
								{showImages && (
									<>
										{allPosts
											.slice(0, visible)
											.map(({ id, posted, title, image, author, slug }) => (
												<motion.li key={id} {...postAni}>
													<Post
														slug={slug}
														date={posted.on}
														title={title}
														image={image}
														author={author}
													/>
												</motion.li>
											))}
									</>
								)}
							</Content>
						</AnimatePresence>
					</OnImagesLoaded>
				</Column>
			</Row>

			<Row isExpanded>
				<Column small={12} medium={10} pushOnMedium={1} mpad>
					<AnimatePresence>
						{visible < allPosts.length && (
							<LoadMore {...loadAni}>
								<Loader {...loaderProps}>{label}</Loader>
							</LoadMore>
						)}
					</AnimatePresence>
				</Column>
			</Row>
		</Jacket>
	);
}

export default Listing;
