// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';
import { h4Styles } from '@type';
import { motion } from 'framer-motion';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		z-index: 2;
		background: ${props.theme.white};
		padding: 2.4rem 0 ${props.theme.padS};

		${breakup.large`
            padding: ${props.theme.padL} 0;
        `}
	`
);

export const Content = styled.ul(
	(props) => css`
		display: grid;
		grid-gap: 2.4rem;

		${breakup.large`
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 6rem 3.6rem;
            align-items: stretch;
        `}
	`
);

export const LoadMore = styled(motion.div)(
	(props) => css`
		margin-top: ${props.theme.padS};

		${breakup.medium`
            margin-top: ${props.theme.padM};
        `}

		${breakup.large`
            margin-top: ${props.theme.padL};
        `}
	`
);

export const Loader = styled.button(
	(props) => css`
		${h4Styles}
		${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}

		position: relative;
		display: inline-block;
		padding: 0;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		cursor: pointer;

		&:hover {
			${breakup.large`
				&:after {
					background-position: -200%;
				}
			`}
		}

		&:after {
			${breakup.large`
				content: attr(data-text);
				position: absolute;
				z-index: 12;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				background: linear-gradient(
					to right,
					rgba(255, 255, 255, 0) 0%,
					rgba(255, 255, 255, 1) 50%,
					rgba(255, 255, 255, 0) 100%
				);
				background-size: 200% 100%;
				background-position: 200%;
				background-repeat: no-repeat;

				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				transition: background-position 0.75s ease-in-out;
			`}
		}
	`
);
