// Imports
// ------
import React, { useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import gsap from 'gsap';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useDato } from './data';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Styles
// ------
import {
	Jacket,
	WhiteBG,
	Sweater,
	Content,
	Background,
	Title,
	Date,
	Author,
	AuthorPhoto,
	AuthorName,
} from './styles';

// Component
// ------
gsap.registerPlugin(ScrollTrigger);

const Featured = () => {
	dayjs.extend(advancedFormat);

	// NOTE • Data
	const { posts } = useDato();

	// NOTE • Date
	const date = dayjs(posts[0].posted.at).format('Do MMMM YYYY');

	// NOTE • Page Transtion Props
	const pageTrans = {
		to: `/news/${posts[0].slug}`,
		exit: { length: 1 },
		entry: { length: 1, delay: 1 },
	};

	// NOTE • Refs
	const trigger = useRef();
	const target = useRef();

	// NOTE • Onload: Animate Scroll
	useEffect(() => {
		const timer = setTimeout(() => {
			gsap.to(target.current, {
				scaleY: 1,
				ease: 'none',
				scrollTrigger: {
					trigger: trigger.current,
					start: '0% 50%',
					end: '100% 50%',
					scrub: 0.5,
					markers: false,
				},
			});
		}, 250);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<Jacket ref={trigger}>
			<WhiteBG ref={target} />

			<Row isExpanded>
				<Column small={12} medium={10} pushOnMedium={1} mpad>
					<Sweater {...pageTrans}>
						<Background>
							<GatsbyImage
								image={posts[0].featuredImage.data}
								alt={posts[0].featuredImage.alt}
							/>
						</Background>

						<Content>
							<Date>{date}</Date>
							<Title data-text={posts[0].title} className='cursor-hover'>
								{posts[0].title}
							</Title>
						</Content>

						<Author>
							<AuthorPhoto>
								<GatsbyImage
									image={posts[0].author.picture.data}
									alt={posts[0].author.picture.alt}
								/>
							</AuthorPhoto>

							<AuthorName isBold>
								<span>by </span>
								{posts[0].author.name}
							</AuthorName>
						</Author>
					</Sweater>
				</Column>
			</Row>
		</Jacket>
	);
};

export default Featured;
